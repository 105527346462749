import { Bloc } from "../BloC/Bloc";
import { LoadingGifState, loadingGifInitialState } from "./LoadingGifState";

export class LoadingGifBloc extends Bloc<LoadingGifState>{

    constructor() {
        super(loadingGifInitialState)
    }

    openAnimationLoadingGif() {
        this.changeState({ ...this.state, view: true });
    }

    closeAnimationLoadingGif() {
        this.changeState({ ...this.state, view: false });
    }

}