import React from 'react'
import { Route, Routes } from 'react-router-dom';
interface Props {
    children: React.ReactNode
}


function RouteWithNotFound({ children }: Props) {
    return (
        <Routes>
            {children}
            <Route path='*' element={<div>Bot Found</div>} />
        </Routes>
    )
}

export default RouteWithNotFound;