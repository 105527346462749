
type Subscription<T> = (state: T) => void;

export abstract class Bloc<T> {
    private internalState: T;
    private listeners: Subscription<T>[] = [];

    constructor(initialState: T) {
        this.internalState = initialState;
    }

    public get state(): T {
        return this.internalState;
    }

    changeState(state: T) {
        debugger;
        this.internalState = state;

        if (this.listeners.length > 0) {
            this.listeners.forEach(listener => listener(this.state));
        }
    }

    subscribe(listener: Subscription<T>) {
        this.listeners.push(listener);
    }

    unsubcribe(listener: Subscription<T>) {
        const index = this.listeners.indexOf(listener);
        if (index > -1) {
            this.listeners.splice(index, 1);
        }
    }
}
