import React, { Suspense, lazy } from 'react';

import '../styles/bootstrap.css';
import '../styles/waves.css';
import '../styles/styles.css';

import { BrowserRouter, Navigate, Route } from 'react-router-dom';


// import { LoginPage } from '../../../features/login_auth/presentation/pages/LoginPage';
import { PrivateRoutes, PublicRoutes } from '../utils/routes';
import AuthGuard from './AuthGuard';
import RouteWithNotFound from '../utils/route-with-not-found';
import { LoaderLogoGif } from '../../../features/home/presentation/components/LoaderLogoGif';
import { createContext } from '../BloC/createContext';
import { LoadingGifBloc } from '../blocs_/LoadingGifBloc';
import * as DependenciesProvider from '../di/DependenciasProvider';


const LoginPage = lazy(() => import('../../../features/login_auth/presentation/pages/LoginPage'));
const DashBoardRoutes = lazy(() => import('./DashBoardRoutes'));

const [blocContext, useBloc] = createContext<LoadingGifBloc>();

export const useLoadingGifBloc = useBloc;

export default function AppRouter() {

    return (
        <>
            <blocContext.Provider value={DependenciesProvider.providerLoadingGifBloc()}>
                <Suspense fallback={<LoaderLogoGif />}>
                    <BrowserRouter>
                        <RouteWithNotFound>

                            <Route path='/' element={<Navigate to={PrivateRoutes.CRM} />} />

                            <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
                            <Route element={<AuthGuard privateValidation={true} />}>
                                <Route path={`${PrivateRoutes.CRM}/*`} element={<DashBoardRoutes />} />
                            </Route>
                        </RouteWithNotFound>
                    </BrowserRouter>
                </Suspense>
            </blocContext.Provider>

        </>


    );
}
