import logo_cencel from '../assets/images/cencel_white.png';
import cencel0 from '../assets/images/cencel0.png';
import bg1 from '../assets/images/bg1.jpg';
import bg2 from '../assets/images/bg2.jpeg';
import bg3 from '../assets/images/bg3.jpeg';
import bg4 from '../assets/images/bg4.jpg';
import bg5 from '../assets/images/bg5.jpeg';
import bg6 from '../assets/images/bg6.jpeg';
import cencelGif from '../assets/images/logoCencel.gif';

//BRAND IMAGES
export const logo_cencel_blanco = logo_cencel;
export const CENCEL0 = cencel0;
export const BG1 = bg1;
export const BG2 = bg2;
export const BG3 = bg3;
export const BG4 = bg4;
export const BG5 = bg5;
export const BG6 = bg6;
export const CENCELGIF = cencelGif;