import React, { memo } from 'react'
import { CENCELGIF } from '../../../../core/presentation/resources/ImageResources'

export const LoaderLogoGif = memo(() => {
    return (
        <div id="divCalling" className="loader loader-curtain is-active" data-brazilian="" data-curtain-text="Cargando...">
            <div className="cssload-container">
                <img src={CENCELGIF} alt="Loading..."/>
            </div>
        </div>

        // <div className="container">
        //     <div className="modal_progress">
        //         <div className="cssload-container">
        //             <img src={CENCELGIF} />
        //         </div>
        //     </div>
        // </div>
    )
})

