export const PublicRoutes = {
    LOGIN: 'login'
};

export const PrivateRoutes = {
    CRM: 'crm', //Como si se especificara la ruta crm/home etc etc
    HOME: 'home',
    TRACING: 'tracing',
    PROSPECT: 'prospect',
    RECORDS: 'records',
};