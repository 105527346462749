// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --colorFontNavBar: #ffffff;
    --colorBGNavBar: #e91e63;
    --colorFontSiderbar: #747474;
    --colorPrimary: #e91e63;
    --bgNavbar: #ffffff;
    --bgContentPage: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/core/presentation/styles/brand_styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,wBAAwB;IACxB,4BAA4B;IAC5B,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;AAC5B","sourcesContent":[":root {\n    --colorFontNavBar: #ffffff;\n    --colorBGNavBar: #e91e63;\n    --colorFontSiderbar: #747474;\n    --colorPrimary: #e91e63;\n    --bgNavbar: #ffffff;\n    --bgContentPage: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
