import { Navigate, Outlet } from "react-router-dom";
import { getSession } from "../../session/Session";
import { PrivateRoutes, PublicRoutes } from "../utils/routes";

interface Props {
    privateValidation: boolean;
}

export const AuthGuard = ({ privateValidation }: Props) => {
    const session = getSession();


    return session ?
        (
            privateValidation ?
                (
                    <Outlet />
                )
                :
                <Navigate replace to={PrivateRoutes.CRM} />

        ) : (
            <Navigate replace to={PublicRoutes.LOGIN} />

        );

};

export default AuthGuard;