// import { Permission } from '../domain/models/Permission';

import { Permission } from "../domain/models/Permission";

export class Session {
    idUser: string;
    idProfile: number;
    shortName: string;
    permissions: Array<Permission>;
    capitals: string;
    constructor({
        idUser = "",
        idProfile = 0,
        permissions = new Array<Permission>(),
        shortName = "",
        capitals = "",
    } = {}) {
        this.idUser = idUser;
        this.idProfile = idProfile;
        this.permissions = permissions;
        this.shortName = shortName;
        this.capitals = capitals;
    }
}

enum SessionKey {
    SESSION = "SESSION",
    TOKEN = "TKN",
    REFRESH_TOKEN = "RFT"
}


export function setToken(token: string) {
    localStorage.setItem(SessionKey.TOKEN, token);
}
export function getToken() {
    return localStorage.getItem(SessionKey.TOKEN);
}

export function setRefreshToken(token: string) {
    localStorage.setItem(SessionKey.REFRESH_TOKEN, token);
}
export function getRefreshToken() {
    return localStorage.getItem(SessionKey.REFRESH_TOKEN);
}

export function setSession(session: Session) {
    console.log("Saving session...", session);

    if (session) {
        localStorage.setItem(SessionKey.SESSION, JSON.stringify(session));
    } else {
        localStorage.removeItem(SessionKey.SESSION);
    }

}

export function getSession(): Session | undefined {
    const sessionString = localStorage.getItem(SessionKey.SESSION);
    if (sessionString) {
        return JSON.parse(sessionString);
    }
    return undefined;
}

export function clearSession() {
    localStorage.clear();
}